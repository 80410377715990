body {
  font-family: 'Times New Roman', sans-serif;
  max-width: 1200px;
  margin: auto;
}
/* global.css */
* {
  text-decoration: none !important;
}

/*header*/
span.HINAFI {
  color: #03A9F4;
}

header {
  text-align: center;
}

header h1 {
  margin: 4% 0% 0% 4%;
  ;
  font-size: 3em;
}

header h2 {
  margin: 1% 4%;
}

header .photo_hm {
  background-color: #ffffff;
}

.underline {
  text-decoration: underline;
  text-decoration-color: #03A9F4;
}


main h3,h2 {
  text-transform: uppercase;
}

.photo_hm,
aside {
  flex: 1;
  
}


/*aside*/

aside {
  background-color: #03A9F4;
  text-decoration: none !important;


}

.photo_hm {
  display: flex;
  justify-content: center;
  align-items: center;

 
}

img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  box-shadow: 0 0 5px 6px #4e7c91;
  margin-top: 32px;
}


aside h2 {
  background-color: #263238;
  color: #03A9F4;
  text-align: center;
  margin-top: 1em;
}

aside p,
aside h3 {
  padding: 0 8%;

}

aside h3 {

  margin-bottom: 0;
}

aside h3+p {
  margin-top: 0;
  
}


.icon {
  display: flex;


  padding: 2%;

  margin: auto;

  border-radius: 50%;
  border: 3px solid #263238;
}

.loisirs {
  display: flex;
}

.loisirs {
  border-bottom-width: 0px;
  border-bottom-style: solid;
  padding-top: 0px;
  padding-bottom: 4%;
}

.loisirs p {
  flex: 1;
}

/*main */
.colonneprincipale {
  margin-left: 1.5em;
  margin-top: -1em;
  display: flex;
}

main p,
main h3 {
  padding: 0 3%;
}

main h2 {
  border-bottom: solid #03A9F4 2px;
  margin: 30px;
}

h2 i {
  color: #03A9F4;
  padding-right: 1%;
}


.date {
  margin: 17% 0 0 0;
  width: 9em;
}

.date i {
  padding-right: 6%;
  color: #03A9F4;
  font-size: 10px;
}

.date .fa-circle {
  font-size: 8px;

}
.fa-golf-ball
{
  font-size: 20px;
}
.adresse {
  margin: 0 0 0 13%;
}


h3 {

  margin: 4% 0;
}


#references .colonneprincipale {


  display: flex;

}

.recomandationcolonne1,
.recomandationcolonne2 {
  flex: 1;

}

/*Media Queries*/
@media (min-width: 768px) and (orientation: landscape) {


  .colonne_deux {
    padding-right: 3%;
    padding-left: 5em;
  }

  header {
    display: flex;
  }

  header .name {
    text-align: left;
  }

  header .photo_hm {
    background: #03A9F4;

  }

  .photo_hm,
  aside {
    flex: 1;

  }

  aside {
    display: flex;
    flex-direction: column;

  }

  #apropos {
    flex-grow: 4;

  }
  #apropos ul li{
    flex-grow: 4;
  text-decoration: none;

  }
  .name,
  main {
    flex: 2;
  }

  .conteneur_aside_main,
  .colonnes_principale {
    display: flex;

  }

 

}

@media screen and (max-width:380px) {

  html {
    font-size: 14px;
    /* rendre les polices plus petites pour mobile */
  }

  p {
    font-size: 1.2rem
      /* cet élément est désormais équivalent à 12px */
  }

  .colonneprincipale {
    margin: 0.2em;

    flex-direction: column;
    text-align: center;
  }

  .adresse {
    margin-left: 0em;

  }

  .date {
    margin: auto;
    width: 6em;
  }
}